<script lang="ts" setup>
  const props = defineProps<{
    show: boolean
    text: string
  }>()

  const emit = defineEmits<{
    (event: 'close'): void
  }>()

  // auto-dismiss after 8 seconds
  watchEffect(() => {
    if (props.show) {
      setTimeout(() => emit('close'), 8000)
    }
  })
</script>

<template>
  <transition name="slide">
    <div v-if="show" class="toast">
      <icon-grilld-burger class="icon-burger" height="24" width="24" />
      <span class="text">{{ text }}</span>
      <icon-ionic-close
        class="icon-close"
        height="24"
        width="24"
        @click="emit('close')"
        @keydown.enter="emit('close')"
      />
    </div>
  </transition>
</template>

<style lang="scss" scoped>
  .toast {
    // colour
    background-color: var(--color-base-red);
    color: var(--color-base-white);

    // shape
    padding: 1rem;
    border-radius: 0.5rem;

    // center at bottom of page
    position: fixed;
    --spacing: 1rem;
    bottom: var(--spacing);
    --max-width: 28rem;
    // fit screen under max width, center above max width
    left: max(var(--spacing), calc((100vw - var(--max-width)) / 2));
    right: max(var(--spacing), calc((100vw - var(--max-width)) / 2));
    z-index: 100;

    // internal layout
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
  }

  .icon-burger {
    width: 1.5rem;
    height: 1.5rem;
  }

  .text {
    flex-grow: 1;
    @include typography-body-small;
  }

  /* Animations */

  .slide-enter-active,
  .slide-leave-active {
    transition: transform 0.5s ease;
  }

  .slide-enter-from,
  .slide-leave-to {
    transform: translateY(calc(100vh + var(--spacing)));
  }
</style>
